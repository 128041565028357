import React, { useContext, useState } from 'react';
import { pt_BR } from '../../../../intl/idioms';
import ShadowBox from '../../../../components/ShadowBox/ShadowBox';
import CheckTerms from './CheckTerms/CheckTerms';
import styles from './styles.module.scss';
import { IntlContext } from '../../../../intl';
import * as translations from './intl';
import Button from '../../../../componentsV2/Button/Button';
import { ThemeContext } from '../../../../themes/ThemeContextParent/ThemeContextParent';
import { isSameOrBeforeDay, sortByValidUntil } from '../../../../utils/date';
import PDFIcon from '../../../../assets/icons/PDFIcon';
import {
  CLAIM_TERMS_OPENED,
  dataLayerClaimEventTrack,
} from '../../../../utils/GTM_helper';
import Link from '../../../../componentsV2/Link/Link';
import ErrorAttentionIcon from '../../../../assets/icons/ErrorAttentionIcon';
import { sanitize } from 'dompurify';

const reduceAcceptChecked = (sum, checked) => (checked ? sum + 1 : sum);

const TermsAndConditions = props => {
  const { claimDetails, setStep } = props;
  const [acceptChecked, setAcceptChecked] = useState([]);

  const { translate, idiom } = useContext(IntlContext);
  const { getGlobalTheme } = useContext(ThemeContext);

  const intl = translate(translations);
  const isBrazil = idiom === pt_BR;
  const themes = getGlobalTheme();
  const acceptSum = acceptChecked.reduce(reduceAcceptChecked, 0);
  const acceptedAll = isBrazil ? acceptSum === 5 : acceptSum === 4;

  const termsDocuments =
    claimDetails &&
    claimDetails.documents &&
    claimDetails.documents
      .filter(document => document.type === 'TERMS_AND_CONDITIONS')
      .filter(document => {
        if (document.valid_until) {
          return isSameOrBeforeDay(
            new Date(`${document.valid_until}T00:00:00`),
            new Date()
          );
        }
        return true;
      })
      .sort(sortByValidUntil);

  const check = index => {
    const _acceptChecked = [...acceptChecked];
    _acceptChecked[index] = !_acceptChecked[index];
    setAcceptChecked(_acceptChecked);
  };

  return (
    <div className={`${styles[themes]} ${styles.container}`}>
      <ShadowBox>
        <div id="mainContent" className={styles.termsArea}>
          <h2 className={styles[themes]}>{intl.WELCOME_MEDIC}</h2>

          {termsDocuments && termsDocuments[0] && (
            <div className={styles.pdf}>
              <PDFIcon width={42} height={54} />
              <div
                className={styles.pdfInfo}
                onClick={() =>
                  dataLayerClaimEventTrack(
                    CLAIM_TERMS_OPENED,
                    'Teleconsultation'
                  )
                }
              >
                <Link
                  href={termsDocuments[0].content_url}
                  label={intl.TERMS_AND_CONDITIONS}
                />
                <span>{claimDetails.name}</span>
              </div>
            </div>
          )}

          {termsDocuments &&
            termsDocuments[1] &&
            termsDocuments[1].valid_until && (
              <p className={`${styles.disclaimer}`}>
                <ErrorAttentionIcon
                  width={18}
                  height={18}
                  aria-label={intl.WARNING}
                />
                <span
                  dangerouslySetInnerHTML={{
                    __html: sanitize(intl.OLD_TERMS_TRAVEL(termsDocuments[1])),
                  }}
                ></span>
              </p>
            )}

          <div className={`${styles.acceptArea} ${styles[themes]}`}>
            <CheckTerms
              isBrazil={isBrazil}
              acceptChecked={acceptChecked}
              check={check}
              styles={styles}
            />
          </div>
        </div>
        <div className={styles.footer}>
          <div className={styles.buttonArea}>
            <Button
              disabled={!acceptedAll}
              onClick={() => setStep('FORM')}
              label={intl.BTN_NEXT_STEP}
            />
          </div>
        </div>
      </ShadowBox>
    </div>
  );
};

export default TermsAndConditions;
