import { useTheme } from '../../themes/ThemeContextParent/ThemeContextParent';
import { joinClassNames } from '../../utils/classNames';
import styles from './index.module.scss';
import CloseIcon from '../../assets/iconsV2/CloseIcon';
import { useMemoTranslations } from '../../intl';
import * as translations from './intl';
import usePopup from '../../hooks/usePopup';

const Popup = () => {
  const { getGlobalTheme } = useTheme();
  const theme = getGlobalTheme();
  const intl = useMemoTranslations(translations);

  const { isShown, hidePopup, content } = usePopup();

  return (
    <div
      className={joinClassNames(
        styles[theme],
        styles.container,
        isShown ? styles.showing : undefined
      )}
    >
      <button
        tabIndex={0}
        type="button"
        className={styles.closeIcon}
        aria-label={intl.CLOSE}
        onClick={hidePopup}
      >
        <span className={styles[theme]} aria-hidden="true">
          <CloseIcon width={24} height={24} />
        </span>
      </button>
      {content}
    </div>
  );
};

export default Popup;
