import { useContext } from 'react';
import { IntlContext } from '../../intl';
import styles from './ListCountries.module.scss';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import { NotificationContext } from '../NotificationContextParent/NotificationContextParent';
import * as translations from './intl';
import Link from '../../componentsV2/Link/Link';
import { useHistory } from 'react-router-dom';

const reorderCountries = countries => {
  return countries.sort((orderA, orderB) => {
    return orderA.order - orderB.order;
  });
};

const ListCountries = ({
  countries,
  column,
  borderLeft,
  continent,
  showCountries = true,
  title = true,
}) => {
  const { idiom, setCountry, countryFullName, translate } =
    useContext(IntlContext);
  const { actions } = useContext(NotificationContext);
  const { getGlobalTheme } = useContext(ThemeContext);
  const history = useHistory();

  const intl = translate(translations);
  const theme = getGlobalTheme();

  const renderCountries = (countries, setCountry, idiom) => {
    const listOrderedCountries = reorderCountries(countries);
    return listOrderedCountries.map(country => {
      const isCountrySelected = country[idiom] === countryFullName;
      const countrySelected = isCountrySelected ? styles.countrySelected : '';
      return (
        <li
          key={country.initials}
          className={`${styles[theme]} ${countrySelected}`}
        >
          <Link
            className={styles.buttonItem}
            onClick={event => {
              event.preventDefault();
              if (isCountrySelected) return;
              setCountry(country.initials);
              actions.toastNotification.setResetAppereanceToast();
              history.push('/');
              window.location.reload();
            }}
            href={window.location.pathname}
            label={country[idiom]}
            icon={false}
          />
        </li>
      );
    });
  };

  const showBorderLeft = borderLeft
    ? `${styles[theme]} ${styles.borderLeft}`
    : '';
  const mapCountries = renderCountries(countries, setCountry, idiom);
  return (
    <div className={showBorderLeft}>
      {title && (
        <h2 className={`${styles[theme]} ${styles.subtitle}`}>{continent}</h2>
      )}
      {showCountries && (
        <ul
          aria-label={intl.LIST_OF_COUNTRIES}
          className={styles.listCountries}
          style={{ columnCount: column }}
        >
          {mapCountries}
        </ul>
      )}
    </div>
  );
};

export default ListCountries;
