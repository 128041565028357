export const LABEL_RENTAL_START = {
  en_US: 'Rental period start *',
  pt_BR: 'Início do período do aluguel *',
  es_ES: 'Comienzo del período de alquiler *',
};

export const LABEL_RENTAL_END = {
  en_US: 'Rental period end *',
  pt_BR: 'Fim do período do aluguel *',
  es_ES: 'Fin del periodo de alquiler *',
};

export const TEXT_RENTAL_PERIOD = {
  en_US: 'Rental period',
  pt_BR: 'Período do aluguel',
  es_ES: 'Período de alquiler',
};

export const TEXT_PURCHASE_DATE = {
  en_US: 'Purchase date *',
  pt_BR: 'Data da compra *',
  es_ES: 'Fecha de compra *',
};

export const TEXT_ADVERTISEMENT_DATE = {
  en_US: 'Advertisement date *',
  pt_BR: 'Data do anúncio *',
  es_ES: 'Fecha del anuncio *',
};

export const LABEL_TRIP_START_DATE = {
  en_US: 'Trip start date *',
  pt_BR: 'Data de início da viagem *',
  es_ES: 'Fecha de inicio del viaje *',
};

export const LABEL_TRIP_END_DATE = {
  en_US: 'Trip end date *',
  pt_BR: 'Data de fim da viagem *',
  es_ES: 'Fecha de fin del viaje *',
};

export const LABEL_TRIP_DELAY_DATE = {
  en_US: 'Date of delay *',
  pt_BR: 'Data de atraso *',
  es_ES: 'Fecha del retraso *',
};
