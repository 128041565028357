/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import styles from './Generate.module.scss';
import { withRouter } from 'react-router-dom';
import { IntlContext } from '../../../intl';
import { StateContext } from '../../../components/StateContextParent/StateContextParent';
import {
  claimsServices,
  productsAndBenefitsServices,
} from '../../../services/index';
import Loading from '../../../components/Loading/Loading';
import TermsAndConditions from './TermsAndConditions/TermsAndConditions';
import ClaimFillForm from './ClaimFillForm/ClaimFillForm';
import HeadingTitle from '../../../components/HeadingTitle/HeadingTitle';
import LoadingError from '../../../components/LoadingError/LoadingError';
import SessionContainer from '../../../components/SessionContainer/SessionContainer';
import { ThemeContext } from '../../../themes/ThemeContextParent/ThemeContextParent';
import {
  CLAIM_STARTED,
  dataLayerClaimEventTrack,
} from '../../../utils/GTM_helper';
import SearchCertificates from './SearchCertificates/SearchCertificates';
import {
  REQUEST_CERTIFICATE_IEMS,
  REQUEST_CERTIFICATE_IEMS_WHITELIST,
} from '../../../utils/environments_variables';
import { AppInsightTrackContext } from '../../../components/AppInsightTrackContextParent/AppInsightTrackContextParent';
import { findBenefitsByExternalReference } from '../../../utils/benefits';
import { emergency_medical_assistance } from '../../../utils/claims_name';
import * as translation from './intl';

const checkClaimsEligibility = async (
  claimType,
  binOrPan,
  setClaimEligibility
) => {
  try {
    const { data: claimTypes } = await claimsServices.getClaimTypes(binOrPan);
    const claimFind = claimTypes.find(
      ct => ct.external_reference === claimType
    );
    if (!claimFind) setClaimEligibility('ERROR');
  } catch (error) {
    setClaimEligibility('ERROR');
  }
};

const loadData = async (claimType, binOrPan, setClaimDetails, idiom) => {
  try {
    let benefits;
    let findBenefit;

    benefits = await productsAndBenefitsServices.getBenefitsByProduct(
      binOrPan,
      idiom,
      false,
      false
    );
    findBenefit = findBenefitsByExternalReference(claimType, benefits.data);

    if (!findBenefit) {
      //try again find the same benefit using as_of_date logic
      benefits = await productsAndBenefitsServices.getBenefitsByProduct(
        binOrPan,
        idiom,
        false,
        true
      );
      findBenefit = findBenefitsByExternalReference(claimType, benefits.data);
    }

    const benefit_id = findBenefit ? findBenefit.benefit_id : claimType;

    const response =
      await productsAndBenefitsServices.getBenefitsDetailByProductV2(
        binOrPan,
        benefit_id,
        idiom,
        false
      );
    setClaimDetails(response.data);
  } catch (error) {
    setClaimDetails('ERROR');
  }
};

const loadShowCertificateArea = async (
  claimType,
  binOrPan,
  country,
  setShowCertificateArea,
  trackEventUserAction
) => {
  try {
    const { data: claimTypes } = await claimsServices.getClaimTypes(binOrPan);
    const claimFind = claimTypes.find(
      ct => ct.external_reference === claimType
    );

    let isSkippable = false;
    if (!(claimFind && claimFind.certificate_type_name)) {
      isSkippable = true;
    }

    const showCertificateArea = country === 'BRAZIL' && !isSkippable;
    const countryInIEMSWhitelist =
      REQUEST_CERTIFICATE_IEMS_WHITELIST.includes(country);
    const showForIEMS =
      claimType === emergency_medical_assistance &&
      REQUEST_CERTIFICATE_IEMS &&
      !countryInIEMSWhitelist;

    setShowCertificateArea(showCertificateArea || showForIEMS);
    trackEventUserAction(
      '#### Claims ### Form - Checked if certificate is skippable',
      { isSkippable }
    );
  } catch (error) {
    setShowCertificateArea('ERROR');
    trackEventUserAction('#### Claims ### Form - ERROR VERIFY SKIP', {
      error,
    });
  }
};

const Generate = props => {
  const { claimType } = props.match.params;
  const { translate } = useContext(IntlContext);
  const intl = translate(translation);
  const [claimDetails, setClaimDetails] = useState('LOADING');
  const [showCertificateArea, setShowCertificateArea] = useState('LOADING');
  const [claimEligibility, setClaimEligibility] = useState('LOADING');
  const [step, setStep] = useState('TERMS');
  const [incidentDate, setIncidentDate] = useState(null);
  const [certificate, setCertificate] = useState(false);
  const { utils } = useContext(StateContext);
  const { idiomForApi } = useContext(IntlContext);
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  const { trackEventUserAction } = useContext(AppInsightTrackContext);

  const binOrPan = utils.getBin(true);
  const country = utils.getCountry();
  const idiom_API = idiomForApi();
  const isTerms = step === 'TERMS';
  const isForm = step === 'FORM';
  const isSearchCertificates = step === 'SEARCH_CERTIFICATES';

  const isLoading =
    claimDetails === 'LOADING' || showCertificateArea === 'LOADING';
  const isError =
    claimEligibility === 'ERROR' ||
    claimDetails === 'ERROR' ||
    showCertificateArea === 'ERROR';
  const isSuccess = !isLoading && !isError;

  useEffect(() => {
    checkClaimsEligibility(claimType, binOrPan, setClaimEligibility);
    loadData(claimType, binOrPan, setClaimDetails, idiom_API);
    loadShowCertificateArea(
      claimType,
      binOrPan,
      country,
      setShowCertificateArea,
      trackEventUserAction
    );
    dataLayerClaimEventTrack(CLAIM_STARTED, claimType);
  }, [claimType]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={`${styles[theme]} ${styles.container}`}>
      {isLoading && (
        <div className={styles.loadingContainer}>
          <Loading surface="Inverse" />
        </div>
      )}
      {isError && (
        <div className={styles.loadingContainer}>
          <LoadingError color="#fff" message={intl.PRODUCT_NOT_COVERAGE} />
        </div>
      )}
      {isSuccess && (
        <SessionContainer>
          <HeadingTitle
            showModalExit
            title={claimDetails.name}
            backLink="/claims"
          />
          {isTerms && (
            <TermsAndConditions
              claimDetails={claimDetails}
              claimType={claimType}
              setStep={setStep}
              showCertificateArea={showCertificateArea}
            />
          )}
          {isSearchCertificates && (
            <SearchCertificates
              claimType={claimType}
              setCertificate={setCertificate}
              incidentDateState={[incidentDate, setIncidentDate]}
              setStep={setStep}
            />
          )}
          {isForm && (
            <ClaimFillForm
              certificate={certificate}
              claimType={claimType}
              setStep={setStep}
              showCertificateArea={showCertificateArea}
            />
          )}
        </SessionContainer>
      )}
    </div>
  );
};

export default withRouter(Generate);
