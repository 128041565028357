import React from 'react';
import ModalInformation from '../../../../components/ModalInformation/ModalInformation';
import { claimsServices } from '../../../../services';
import { NOTIFICATIONS_ENABLE } from '../../../../utils/environments_variables';

const redirectToUpload = (claim_id, history, actions) => {
  history.push(`/claim-type/${claim_id}/upload`);
  actions.modal.closeModal();
};

const redirectToClaims = (claim_id, history, actions) => {
  history.push('/profile/claims');
  actions.modal.closeModal();
};

const setOptionalToTop = object => {
  let mandatory = object.slice(0);
  return mandatory.sort(function (a, b) {
    return a.is_mandatory - b.is_mandatory;
  });
};

const getRequiredDocuments = expectedDocuments =>
  expectedDocuments.filter(document => document.is_mandatory === true);

const checkComplete = (requiredDocuments, alreadyAttached) => {
  for (const requiredDoc of requiredDocuments) {
    const find = alreadyAttached.find(
      alreadyAttach =>
        alreadyAttach.document_type_name === requiredDoc.document_type_name
    );
    if (!find) {
      return false;
    }
  }
  return true;
};

const redirectHome = (history, actions) => {
  history.push('/dashboard');
  actions.modal.closeModal();
};

const redirectClaims = (history, actions) => {
  history.push('/profile/claims');
  actions.modal.closeModal();
};

const concludeUpload = async (
  actions,
  intl,
  claimId,
  notificationActions,
  history
) => {
  try {
    actions.modal.closeModal();
    actions.loadingFullScreen.showLoadingFullScreen(intl.COMPLETING_YOUR_CLAIM);

    await claimsServices.postConcludeAttachment(claimId);

    if (NOTIFICATIONS_ENABLE) {
      notificationActions.bellNotification.updateBellNotification();
    }
    actions.loadingFullScreen.hideLoadingFullScreen();
    actions.modal.showModal(
      false,
      false,
      <ModalInformation
        type="success"
        message={intl.HAS_BEEN_SENT}
        subtitle={intl.HAS_BEEN_SENT_SUBTITLE}
        textBtn={intl.GO_HOME}
        clickBtn={() => redirectHome(history, actions)}
        textBtnDeny={intl.YOUR_CLAIMS}
        clickBtnDeny={() => redirectClaims(history, actions)}
      />,
      true
    );
  } catch (error) {
    actions.loadingFullScreen.hideLoadingFullScreen();
    actions.modal.showModal(
      false,
      false,
      <ModalInformation
        type="error"
        message={intl.UNEXPECTED_ERROR_OCCURRED}
      />,
      true
    );
  }
};

export const submit = async (
  claim,
  values,
  intl,
  actions,
  history,
  bin,
  idiom,
  notificationActions
) => {
  try {
    actions.loadingFullScreen.showLoadingFullScreen(intl.SUBMITTING_APPEAL);
    const { comment } = values;
    const description = comment;
    await claimsServices.reopenByClaimId(claim.claim_id, { description });
    const [expectedResponse, claimInfoResponse] = await Promise.all([
      claimsServices.expectedDocuments(bin, idiom, claim.claim_id),
      claimsServices.getClaimInfoByClaimId(claim.claim_id, idiom),
    ]);
    const requiredDocuments = getRequiredDocuments(expectedResponse.data);
    const alreadyAttached = claimInfoResponse.data.attachments;
    const isAllRequiredDocumentsAttached = checkComplete(
      requiredDocuments,
      alreadyAttached
    );
    actions.loadingFullScreen.hideLoadingFullScreen();
    actions.modal.showModal(
      false,
      false,
      <ModalInformation
        type="success"
        message={intl.TITLE_APPEAL_SUCCESS}
        subtitle={
          isAllRequiredDocumentsAttached
            ? intl.SUBTITLE_APPEAL_SUCCESS_REQUIRED_DOCUMENTS_ATTACHED
            : intl.SUBTITLE_APPEAL_SUCCESS_REQUIRED_DOCUMENTS_MISSING
        }
        textBtn={
          isAllRequiredDocumentsAttached
            ? intl.BTN_FINISH
            : intl.LABEL_ATTACH_DOCUMENTS
        }
        clickBtn={
          isAllRequiredDocumentsAttached
            ? () =>
                concludeUpload(
                  actions,
                  intl,
                  claim.claim_id,
                  notificationActions,
                  history
                )
            : () => redirectToUpload(claim.claim_id, history, actions)
        }
        textBtnDeny={
          isAllRequiredDocumentsAttached && intl.LABEL_ATTACH_DOCUMENTS
        }
        buttonDenyType={'gold'}
        clickBtnDeny={
          isAllRequiredDocumentsAttached &&
          (() => redirectToUpload(claim.claim_id, history, actions))
        }
      />,
      true,
      false
    );
  } catch (error) {
    actions.loadingFullScreen.hideLoadingFullScreen();
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.error_description ===
        'Only a rejected claim can be appeal'
    ) {
      actions.modal.showModal(
        false,
        false,
        <ModalInformation
          type="error"
          message={intl.ALREADY_OPEN_ERROR}
          textBtn={intl.LABEL_YOUR_CLAIMS}
          clickBtn={() => redirectToClaims(claim.claim_id, history, actions)}
        />,
        true,
        false
      );
      return;
    }

    actions.modal.showModal(
      false,
      false,
      <ModalInformation type="error" message={intl.UNEXPECTED_ERROR} />,
      true,
      false
    );
  }
};
