import React, { createRef, useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { componentByCertificateType } from './componentByCertificateType';
import { AppInsightTrackContext } from '../../../../components/AppInsightTrackContextParent/AppInsightTrackContextParent';
import { checkErrorsOnForm, checkErrorsOnSession } from './checkErrorsOnForm';
import { submitCertificate } from './submit/submit';
import styles from './CertificateFormFill.module.scss';
import { checkGeneralInfoChange } from './submit/checkGeneralInfoChange/checkGeneralInfoChange';
import {
  dataLayerCertificateEventTrack,
  CERTIFICATE_COMPLETED_STEP,
} from '../../../../utils/GTM_helper';
import { IntlContext } from '../../../../intl';
import { StateContext } from '../../../../components/StateContextParent/StateContextParent';
import ModalInformation from '../../../../components/ModalInformation/ModalInformation';
import * as translationsTravel from './Travel/intl';
import usePopup from '../../../../hooks/usePopup';
import { ENABLE_CSAT } from '../../../../utils/environments_variables';

const CertificateFormFill = props => {
  const { certificateType } = props;
  const { idiomForApi, translate } = useContext(IntlContext);
  const { actions } = useContext(StateContext);
  const { trackEventUserAction } = useContext(AppInsightTrackContext);
  const { showPopup } = usePopup();

  const CertificateFormComponent = componentByCertificateType[certificateType];
  const formRef = createRef();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [prefix, setPrefix] = useState('');
  const [initialValuesCompare, setInitialValuesCompare] = useState({});
  const [detailedError, setDetailedError] = useState(null);

  const intlTravel = translate(translationsTravel);
  let values = {};
  let schemas = {};

  const showCsatSurvey = () => {
    if (!ENABLE_CSAT) return;

    setTimeout(() => {
      showPopup(
        <iframe src={intlTravel.LINK_CSAT} className={styles.csatSurvey} />
      );
    }, 3000);
  };

  const updateForm = (index, childValues, validationSchema) => {
    if (childValues !== values[index]) {
      values[index] = childValues;
    }
    if (validationSchema !== schemas[index]) {
      schemas[index] = validationSchema;
    }
  };

  const submit = async () => {
    setIsSubmitting(true);
    let areFormsOk = false;
    try {
      areFormsOk = await checkErrorsOnForm(
        values,
        schemas,
        formRef.current,
        trackEventUserAction,
        true
      );
    } catch (error) {
      if (certificateType === 'travel_insurance') {
        if (error && error.path && String(error.path).includes('dependents')) {
          actions.modal.showModal(
            false,
            true,
            <ModalInformation
              type="error"
              message={intlTravel.DEPENDENTS_MANDATORY_INFORMATION_MISSING}
              subtitle={
                intlTravel.DEPENDENTS_MANDATORY_INFORMATION_MISSING_SUBTITLE
              }
            />,
            true,
            false
          );
          setDetailedError(error);
        }
      }
    }

    trackEventUserAction(
      '#### (CERTIFICATE) CLICOU NO SUBMIT -- VAI VALIDAR O FORMULÁRIO',
      areFormsOk
    );
    if (areFormsOk) {
      const _props = {
        ...props,
        values: values,
        formRef: formRef.current,
        history: props.history,
        state: { isSubmitting, prefix, initialValuesCompare },
        initialValuesCompare: initialValuesCompare,
        setInitialValuesCompare: setInitialValuesCompare,
        trackEventUserAction,
        idiomForApi: idiomForApi,
        showCsatSurvey,
      };
      try {
        trackEventUserAction(
          '#### (CERTIFICATE) CLICOU NO SUBMIT -- ENTROU NO SUBMIT APÓS VALIDAÇÃO',
          _props
        );
        await submitCertificate(_props);
      } catch (error) {
        setIsSubmitting(false);
      }
    }
    setIsSubmitting(false);
  };

  const generalInformationCheck = () => {
    const _props = {
      ...props,
      values: values,
      formRef: formRef.current,
      history: props.history,
      state: { isSubmitting, prefix, initialValuesCompare },
      initialValuesCompare: initialValuesCompare,
      setInitialValuesCompare: setInitialValuesCompare,
      trackEventUserAction,
    };
    checkGeneralInfoChange(_props);
  };

  const openTargetAcordeon = async (
    currentAcordeon,
    targetAcordeon,
    validation = true,
    throwableError = false
  ) => {
    const checkErrorOnSession = await checkErrorsOnSession(
      currentAcordeon,
      targetAcordeon,
      schemas,
      values,
      validation,
      formRef.current,
      throwableError
    );

    if (checkErrorOnSession) {
      dataLayerCertificateEventTrack(
        CERTIFICATE_COMPLETED_STEP,
        certificateType,
        getStepByTarget(currentAcordeon)
      );
    }

    return checkErrorOnSession;
  };

  const getStepByTarget = target => {
    let step = '';
    switch (target) {
      case 'dependents':
        step = 2;
        break;

      case 'tripInformation':
        step = 3;
        break;

      default:
        step = 1;
        break;
    }
    return step;
  };
  return (
    <div className={`${styles.certificateFormFill} `}>
      <CertificateFormComponent
        {...props}
        ref={formRef}
        updateForm={updateForm}
        prefixOptions={{ prefix, setPrefix }}
        submit={submit}
        openTargetAcordeon={openTargetAcordeon}
        generalInformationCheck={generalInformationCheck}
        setInitialValuesCompare={setInitialValuesCompare}
        detailedError={detailedError}
        setDetailedError={setDetailedError}
      />
    </div>
  );
};

export default withRouter(CertificateFormFill);
