import moment from 'moment';
import { ENABLE_CERTIFICATE_PDF_VERSION_2024 } from './environments_variables';

export const filterNotExpired = (certificates, filterDate) => {
  return certificates.filter(certificate => {
    if (
      certificate.valid_until === undefined ||
      certificate.valid_until === null
    ) {
      return false;
    }
    return (
      moment(certificate.valid_until, 'YYYY-MM-DD') >=
        moment(filterDate, 'YYYY-MM-DD') &&
      moment(certificate.valid_from, 'YYYY-MM-DD') <=
        moment(filterDate, 'YYYY-MM-DD')
    );
  });
};

export const getUrlPdf = (code, clientType) => {
  let arrayPDF = [
    {
      code: 'billete_auto_rental_insurance',
      pdf: ENABLE_CERTIFICATE_PDF_VERSION_2024
        ? process.env.REACT_APP_RESUMO_VEICULOS_LOCADORA
        : clientType === 'INDIVIDUAL'
        ? process.env.REACT_APP_RESUMO_VEICULOS_LOCADORA_OUTDATED
        : process.env.REACT_APP_RESUMO_VEICULOS_LOCADORA_EMPRESARIAL_OUTDATED,
    },
    {
      code: 'certificate_seguro_compra',
      pdf: ENABLE_CERTIFICATE_PDF_VERSION_2024
        ? process.env.REACT_APP_RESUMO_PROTECAO_COMPRA
        : clientType === 'INDIVIDUAL'
        ? process.env.REACT_APP_RESUMO_PROTECAO_COMPRA_OUTDATED
        : process.env.REACT_APP_RESUMO_PROTECAO_COMPRA_EMPRESARIAL_OUTDATED,
    },
  ];
  let filterByCode = arrayPDF.find(pdf => {
    return pdf.code === code;
  });
  return filterByCode ? filterByCode.pdf : '';
};
