import React, { useState, useEffect, useContext } from 'react';
import { IntlContext } from '../../intl';
import {
  claimsServices,
  enrollService,
  productsAndBenefitsServices,
} from '../../services';
import { StateContext } from '../../components/StateContextParent/StateContextParent';
import Loading from '../../components/Loading/Loading';
import styles from './PrivateClaims.module.scss';
import Claims from './Claims/Claims';
import RecentClaims from '../../components/Private/Claims/RecentClaims/RecentClaims';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import { AppInsightTrackContext } from '../../components/AppInsightTrackContextParent/AppInsightTrackContextParent';
import { format } from 'date-fns';
import { matchClaimsAndBenefits } from './config_claim';
import {
  enableCrosselling,
  checkAcessCross,
} from '../../utils/verifyAccessCrosselling';

export const mergeBenefits = async (
  claimTypes,
  benefits,
  binOrPan,
  idiomForApi
) => {
  const cloneClaimTypes = JSON.parse(JSON.stringify(claimTypes));

  const matchBenefits = await matchClaimsAndBenefits(
    claimTypes,
    benefits,
    binOrPan,
    idiomForApi
  );

  cloneClaimTypes.map(value => {
    return (value.info = matchBenefits.filter(benefit => {
      return benefit.external_reference === value.external_reference;
    }));
  });

  return cloneClaimTypes;
};

const sortExtensionsByDate = (a, b) => {
  return (
    Number(a.product_criteria.effective_period.to) -
    Number(b.product_criteria.effective_period.to)
  );
};

const PrivateClaims = () => {
  const { idiomForApi } = useContext(IntlContext);
  const { utils } = useContext(StateContext);
  const { isAfluent } = useContext(ThemeContext);
  const { trackEventUserAction } = useContext(AppInsightTrackContext);

  const [benefits, setBenefits] = useState([]);
  const [mergeBenefitsClaims, setMergeBenefitsClaims] = useState([]);
  const [loading, setLoading] = useState(true);

  const binOrPan = utils.getBin(true);
  const cardSelected = utils.getSelectedCard();
  const email = utils.getEmail();

  const loadBenefits = async () => {
    setLoading(true);
    try {
      trackEventUserAction(
        '#### (CLAIMS LIST) CHAMANDO REQUEST DE CLAIM TYPES',
        {}
      );
      const { data: claimTypes } = await claimsServices.getClaimTypes(
        binOrPan,
        idiomForApi()
      );

      const { data: benefits } =
        await productsAndBenefitsServices.getBenefitsByProduct(
          binOrPan,
          idiomForApi(),
          false,
          true
        );
      setBenefits(benefits);

      trackEventUserAction(
        '#### (CLAIMS LIST) CARREGOU A LISTA DE CLAIM TYPES',
        claimTypes
      );
      const newData = await mergeBenefits(
        claimTypes,
        benefits,
        binOrPan,
        idiomForApi()
      );
      setMergeBenefitsClaims(newData);
    } catch (error) {
      trackEventUserAction(
        '#### (CLAIMS LIST) ERROR - NÃO FOI POSSÍVEL CARREGAR A LISTA DE CLAIMS',
        {
          error,
          error_response: error && error.response ? error.response : '',
        }
      );
      setBenefits([]);
      setMergeBenefitsClaims([]);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (cardSelected && cardSelected.external_reference) {
      loadBenefits();
    }
  }, [cardSelected]);

  const bgColor = isAfluent() ? '#021E4C' : '#1434CB';

  const bgStyle = {
    background: `linear-gradient(
    to bottom,
    ${bgColor} 0%,
    ${bgColor} 420px,
    white 420px,
    white 100%
  )`,
  };

  return (
    <>
      <div style={bgStyle} className={styles.container}>
        {loading ? (
          <div className={styles.loadingContainer}>
            <Loading surface="Inverse" />
          </div>
        ) : (
          <>
            <RecentClaims benefits={benefits} />
            <div id="mainContent" className={styles.contentClaims}>
              <Claims
                benefits={benefits}
                mergeBenefitsClaims={mergeBenefitsClaims}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default PrivateClaims;
