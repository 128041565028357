import { createContext, ReactNode, useContext, useState } from 'react';

interface PopupContext {
  showPopup: (content: ReactNode) => void;
  hidePopup: () => void;
  content: ReactNode | null;
  isShown: boolean;
}

const PopupContext = createContext({
  showPopup: _content => null,
  hidePopup: () => null,
  content: null,
  isShown: false,
} as PopupContext);

export const PopupContextParent = ({ children }) => {
  const [content, setContent] = useState<ReactNode | null>(null);
  const [isShown, setIsShown] = useState<boolean>(false);

  const showPopup = (content: ReactNode) => {
    setContent(content);
    setIsShown(true);
  };
  const hidePopup = () => {
    setIsShown(false);
  };

  return (
    <PopupContext.Provider value={{ showPopup, hidePopup, content, isShown }}>
      {children}
    </PopupContext.Provider>
  );
};

const usePopup = () => useContext(PopupContext);

export default usePopup;
