import DOMPurify from 'dompurify';
import TooltipIcon from '../../assets/icons/TooltipIcon';
import { useTheme } from '../../themes/ThemeContextParent/ThemeContextParent';
import { joinClassNames } from '../../utils/classNames';
import styles from './HighlightNote.module.scss';
import Link from '../../componentsV2/Link/Link';

const HighLightNote = ({ note, link = '' }) => {
  const { getGlobalTheme } = useTheme();
  const theme = getGlobalTheme();

  return (
    <div className={joinClassNames(styles.highlightContainer, styles[theme])}>
      <aside className={joinClassNames(styles.iconArea, styles[theme])}>
        <TooltipIcon color="#fff" width={20} height={20} />
      </aside>

      <div className={joinClassNames(styles.highlightContent, styles[theme])}>
        <span
          className={styles.highlightNote}
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(note) }}
        />
        {link && (
          <Link
            href={link.to}
            className={styles.highLightLink}
            icon={false}
            label={link.label}
          />
        )}
      </div>
    </div>
  );
};

export default HighLightNote;
