import React, { useState, useContext, useEffect } from 'react';
import { productsAndBenefitsServices } from '../../../services';
import { StateContext } from '../../../components/StateContextParent/StateContextParent';
import { IntlContext } from '../../../intl';
import SessionContainer from '../../../components/SessionContainer/SessionContainer';
import HeadingTitle from '../../../components/HeadingTitle/HeadingTitle';
import * as translations from './intl';
import TermsAndConditions from './TermsAndConditions/TermsAndConditions';
import TeleconsultationFormFill from './TeleconsultationFormFill/TeleconsultationFormFill';
import Loading from '../../../components/Loading/Loading';
import styles from '../Generate/Generate.module.scss';
import LoadingError from '../../../components/LoadingError/LoadingError';
import { ThemeContext } from '../../../themes/ThemeContextParent/ThemeContextParent';
import StepClaim from '../StepClaim/StepClaim';
import { isBR } from '../../../utils/country';
import { useHistory } from 'react-router-dom';
import { findBenefitsByExternalReference } from '../../../utils/benefits';

const loadData = async (
  binOrPan,
  setclaimTypeDetails,
  idiom,
  country,
  history
) => {
  try {
    const idiomKey = idiom.replace('_', '-');
    const benefits = await productsAndBenefitsServices.getBenefitsByProduct(
      binOrPan,
      idiomKey,
      false,
      false
    );

    const findBenefit = findBenefitsByExternalReference(
      'Teleconsultation',
      benefits.data
    );

    if (!isBR(country) && findBenefit && findBenefit.benefit_id) {
      history.push(`/your-benefits/${findBenefit.benefit_id}`);
      return;
    }

    const benefit =
      await productsAndBenefitsServices.getBenefitsDetailByProductV2(
        binOrPan,
        findBenefit ? findBenefit.benefit_id : 'Teleconsultation',
        idiomKey,
        false
      );
    setclaimTypeDetails(benefit.data);
  } catch (error) {
    setclaimTypeDetails('ERROR');
  }
};

const Teleconsultation = () => {
  const [claimDetails, setclaimTypeDetails] = useState('LOADING');
  const [step, setStep] = useState('TERMS');

  const { utils } = useContext(StateContext);
  const { idiom, translate, country } = useContext(IntlContext);
  const { getGlobalTheme } = useContext(ThemeContext);
  const history = useHistory();

  const intl = translate(translations);
  const binOrPan = utils.getBin(true);
  const theme = getGlobalTheme();

  const steps = ['TERMS', 'FORM'];
  const stepIndex = steps.findIndex(element => element === step);
  const isTerms = step === 'TERMS';
  const isForm = step === 'FORM';

  useEffect(() => {
    loadData(binOrPan, setclaimTypeDetails, idiom, country, history);
  }, [claimDetails]);

  const isLoading = claimDetails === 'LOADING';
  const isError = claimDetails === 'ERROR';
  const successLoad = !isLoading && !isError;

  const stepNames = [intl.TEXT_STEP_1, intl.TEXT_STEP_2];
  return (
    <div className={`${styles[theme]} ${styles.container}`}>
      {isLoading && (
        <div className={styles.loadingContainer}>
          <Loading surface="Inverse" />
        </div>
      )}
      {isError && (
        <div className={styles.loadingContainer}>
          <LoadingError color="#fff" />
        </div>
      )}
      {successLoad && (
        <SessionContainer>
          <HeadingTitle
            title={`${claimDetails.name}`}
            subtitle={intl.SUBTITLE_BENEFITS}
            backLink="/your-benefits"
          />
          <StepClaim currentStep={stepIndex + 1} stepNames={stepNames} />
          {isTerms && (
            <TermsAndConditions claimDetails={claimDetails} setStep={setStep} />
          )}
          {isForm && <TeleconsultationFormFill setStep={setStep} />}
        </SessionContainer>
      )}
    </div>
  );
};

export default Teleconsultation;
