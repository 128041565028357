import React, { useContext } from 'react';
import CheckBox from '../../../../../components/CheckBox/CheckBox';
import { IntlContext } from '../../../../../intl';
import * as translations from '../intl';
import { ThemeContext } from '../../../../../themes/ThemeContextParent/ThemeContextParent';

const CheckTerms = props => {
  const { acceptChecked, check, isBrazil, styles } = props;

  const { translate } = useContext(IntlContext);
  const { getGlobalTheme } = useContext(ThemeContext);

  const intl = translate(translations);
  const themes = getGlobalTheme();

  return (
    <div>
      <CheckBox
        dataTestid="checkbox"
        onClick={() => check(0)}
        value={acceptChecked[0]}
      >
        {intl.ACCEPT_TERMS}
      </CheckBox>
      <CheckBox
        dataTestid="checkbox"
        onClick={() => check(1)}
        value={acceptChecked[1]}
      >
        {intl.CONDIRM_OUTSIDE_MY_COUNTRY}
      </CheckBox>
      <CheckBox
        dataTestid="checkbox"
        onClick={() => check(2)}
        value={acceptChecked[2]}
      >
        {intl.CONFIRM_PURCHASE}
      </CheckBox>
      <p className={`${styles.condition} ${styles[themes]}`}>
        {intl.PURCHASE_BY_POINTS}
      </p>
      {isBrazil && (
        <CheckBox
          dataTestid="checkbox"
          onClick={() => check(3)}
          value={acceptChecked[3]}
        >
          {intl.HAVE_INSURANCE_TICKET}
        </CheckBox>
      )}
      <CheckBox
        dataTestid="checkbox"
        onClick={() => check(4)}
        value={acceptChecked[4]}
      >
        {intl.CONFIRM_REQUIRED_CONDITIONS}
      </CheckBox>

      <ul
        data-testid="diseaseList"
        className={`${styles.diseasesList} ${styles[themes]}`}
      >
        <li>{intl.MEDICAL_CONDITIONS_1} </li>
        <li>{intl.MEDICAL_CONDITIONS_2}</li>
        <li>{intl.MEDICAL_CONDITIONS_3}</li>
        <li>{intl.MEDICAL_CONDITIONS_4}</li>
        <li>{intl.MEDICAL_CONDITIONS_5}</li>
        <li>{intl.MEDICAL_CONDITIONS_6}</li>
        <li>{intl.MEDICAL_CONDITIONS_7}</li>
        <li>{intl.MEDICAL_CONDITIONS_8}</li>
        <li>{intl.MEDICAL_CONDITIONS_9}</li>
        <li>{intl.MEDICAL_CONDITIONS_10}</li>
        <li>{intl.MEDICAL_CONDITIONS_11}</li>
        <li>{intl.MEDICAL_CONDITIONS_12}</li>
        <li>{intl.MEDICAL_CONDITIONS_13}</li>
        <li>{intl.MEDICAL_CONDITIONS_14}</li>
        <li>{intl.MEDICAL_CONDITIONS_15}</li>
        <li>{intl.MEDICAL_CONDITIONS_16}</li>
        <li>{intl.MEDICAL_CONDITIONS_17}</li>
        <li>{intl.MEDICAL_CONDITIONS_18}</li>
        <li>{intl.MEDICAL_CONDITIONS_19}</li>
        <li>{intl.MEDICAL_CONDITIONS_20}</li>
        <li>{intl.MEDICAL_CONDITIONS_21}</li>
        <li>{intl.MEDICAL_CONDITIONS_22}</li>
        <li>{intl.MEDICAL_CONDITIONS_23}</li>
        <li>{intl.MEDICAL_CONDITIONS_24}</li>
        <li>{intl.MEDICAL_CONDITIONS_25}</li>
        <li>{intl.MEDICAL_CONDITIONS_26}</li>
        <li>{intl.MEDICAL_CONDITIONS_27}</li>
      </ul>
    </div>
  );
};

export default CheckTerms;
