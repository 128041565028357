import { SVGIconProps } from '../../@types/Icons';
import { useIconColors } from '../../hooks/useIconColors';

const CloseIcon = (props: SVGIconProps) => {
  const colors = useIconColors(props);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill="none"
      {...props}
    >
      <path
        fill={colors.primary}
        d="m8 9.415 4.907 4.907 1.414-1.414L9.414 8l4.909-4.909-1.415-1.414L8 6.586 3.095 1.682 1.681 3.096l4.905 4.905-4.905 4.905 1.414 1.414L8 9.415Z"
      />
    </svg>
  );
};
export default CloseIcon;
