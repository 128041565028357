import { format } from 'date-fns';
import { es, ptBR } from 'date-fns/locale';

export const ACCEPTING_TERMS_AND_CONDITIONS = {
  en_US: 'Accepting terms and conditions',
  es_ES: 'Aceptación  de términos y condiciones',
  pt_BR: 'Aceitação dos termos e condições',
};

export const TERMS_AND_CONDITIONS = {
  en_US: 'Terms and conditions',
  es_ES: 'Términos y condiciones',
  pt_BR: 'Termos e condições',
};

export const ACCEPT_TERMS = {
  en_US: 'I have read and accept the terms and conditions.',
  es_ES: 'He leido y acepto los terminos y condiciones.',
  pt_BR: 'Eu li e aceito os termos e condições.',
};

export const BTN_NEXT_STEP = {
  en_US: 'Next step',
  es_ES: 'Próximo paso',
  pt_BR: 'Próximo passo',
};

export const TEXT_INCLUDE_CERTIFICATE = {
  en_US: 'Continue',
  pt_BR: 'Incluir o bilhete de seguro',
  es_ES: 'Continúe',
};

export const MESSAGE_WITHOUT_CERTIFICATE = {
  en_US:
    'You are creating an International Emergency Medical Services claim without a Travel Certificate associated. Are you sure?',
  es_ES:
    'Está creando una reclamación de servicios médicos de emergencia internacional sin un certificado de viaje asociado. ¿Está seguro?',
  pt_BR: '',
};

export const MESSAGE_EFFECTIVE_OCTOBER = {
  en_US: 'Effective October 1 2021, a Travel Certificate must be generated.',
  es_ES:
    'A partir del 1 de octubre de 2021, se debe generar un Certificado de viaje.',
  pt_BR: '',
};

export const TEXT_YES = {
  en_US: "Yes, I'm sure",
  es_ES: 'Sí, estoy seguro',
  pt_BR: '',
};

export const TEXT_NO = {
  en_US: 'No, associate a certificate',
  es_ES: 'No, asociar un certificado',
  pt_BR: '',
};

export const ACCEPT_TERMS_PRICE_PROTECTION_1 = {
  en_US:
    'I certify that the card selected for the opening of the claim was the same one I used to make the purchase of the claimed product and that I am the holder of this card.',
  es_ES:
    'Certifico que la tarjeta seleccionada para la apertura del reclamo fue la misma utilizada para realizar la compra del producto reclamado y que esta es de mi titularidad.',
  pt_BR:
    'Certifico que o cartão selecionado para abertura do sinistro foi o mesmo utilizado para realizar a compra do produto reclamado e que este é de minha titularidade.',
};

export const ACCEPT_TERMS_PRICE_PROTECTION_2 = {
  en_US:
    'I declare that the claimed product is not intended for commercial, professional or resale use. It was acquired for my own use or as a gift. ',
  es_ES:
    'Declaro que el producto reclamado no es destinado a uso comercial, profesional o para reventa. El mismo fue adquirido para uso propio o para regalo.',
  pt_BR:
    'Declaro que o produto reclamado não é destinado a uso comercial, profissional ou revenda. O mesmo foi adquirido para uso próprio ou para presente.',
};

export const ACCEPT_TERMS_PRICE_PROTECTION_3 = {
  en_US:
    'I declare that I don’t have other open claims for the product claimed here.',
  es_ES:
    'Declaro que no poseo otros reclamos abiertos para el producto aquí reclamado.',
  pt_BR:
    'Declaro que não possuo outro(s) sinistro(s) abertos para o produto aqui reclamado.',
};

export const TEXT_STEP_1 = {
  en_US: 'Terms and conditions',
  es_ES: 'Términos y condiciones',
  pt_BR: 'Termos e condições',
};

export const TEXT_STEP_2 = {
  en_US: 'Certificate selection',
  es_ES: 'Selección de certificado',
  pt_BR: 'Seleção de bilhete',
};

export const TEXT_STEP_3 = {
  en_US: 'Claim form',
  es_ES: 'Formulario de reclamo',
  pt_BR: 'Formulário do sinistro',
};

export const TEXT_STEP_4 = {
  en_US: 'Sending documents',
  es_ES: 'Envío de documentos',
  pt_BR: 'Envio de documentos',
};

export const PRICE_PROTECTION_DISCLAIMER_BR = {
  en_US: '',
  es_ES: '',
  pt_BR: `A Visa não é provedora dos seguros. Este seguro e sua correspondente cobertura são oferecidos pela AIG Seguros Brasil S/A, CNPJ/MF n.º 33.040.981/0001-50, (a “AIG”) aos portadores de Cartões Visa elegíveis. As descrições deste
  benefício não são apólices de seguro. O seguro está sujeito aos termos e condições gerais, limitações e exclusões da apólice emitida pela AIG, sendo possíveis alterações ou cancelamento dos termos e condições gerais do seguro.`,
};

export const OLD_TERMS_TRAVEL = {
  en_US: document =>
    `<i>For trips made until <strong>${format(
      new Date(`${document.valid_until}T00:00:00`),
      'LLLL d, yyyy'
    )}</strong>, please refer to the <a href="${
      document.content_url
    }" aria-label="Terms and Conditions" target="_blank" rel="noopener noreferrer">Terms and Conditions</a> applicable to that period.</i>`,
  es_ES: document =>
    `<i>Para viajes realizados hasta el <strong>${format(
      new Date(`${document.valid_until}T00:00:00`),
      "d 'de' LLLL 'de' yyyy",
      {
        locale: es,
      }
    )}</strong>, consulte los <a href="${
      document.content_url
    }" aria-label="Términos y Condiciones" target="_blank" rel="noopener noreferrer">Términos y Condiciones</a> aplicables a ese período.</i>`,
  pt_BR: document =>
    `<i>Para viagens realizadas até <strong>${format(
      new Date(`${document.valid_until}T00:00:00`),
      "d 'de' LLLL 'de' yyyy",
      {
        locale: ptBR,
      }
    )}</strong>, consulte os <a href="${
      document.content_url
    }" aria-label="Termos e Condições - nova aba" target="_blank" rel="noopener noreferrer">Termos e Condições</a> aplicáveis a esse período.</i>`,
};
export const OLD_TERMS_PURCHASE = {
  en_US: document =>
    `<i>For purchases made until <strong>${format(
      new Date(`${document.valid_until}T00:00:00`),
      'LLLL d, yyyy'
    )}</strong>, please refer to the <a href="${
      document.content_url
    }" aria-label="Terms and Conditions" target="_blank" rel="noopener noreferrer">Terms and Conditions</a> applicable to that period.</i>`,
  es_ES: document =>
    `<i>Para compras realizadas hasta el <strong>${format(
      new Date(`${document.valid_until}T00:00:00`),
      "d 'de' LLLL 'de' yyyy",
      {
        locale: es,
      }
    )}</strong>, consulte los <a href="${
      document.content_url
    }" aria-label="Términos y Condiciones" target="_blank" rel="noopener noreferrer">Términos y Condiciones</a> aplicables a ese período.</i>`,
  pt_BR: document =>
    `<i>Para compras realizadas até <strong>${format(
      new Date(`${document.valid_until}T00:00:00`),
      "d 'de' LLLL 'de' yyyy",
      {
        locale: ptBR,
      }
    )}</strong>, consulte os <a href="${
      document.content_url
    }" aria-label="Termos e Condições - nova aba" target="_blank" rel="noopener noreferrer">Termos e Condições</a> aplicáveis a esse período.</i>`,
};

export const IEMS_ARGENTINA_DISCLAIMER = {
  en_US: '',
  es_ES:
    'AXA no es responsable por las tasas cobradas eventualmente por el banco receptor/políticas gubernamentales sobre la transferencia internacional del reembolso. Todas las cantidades mencionadas se expresan en dólares de los EE. UU',
  pt_BR: '',
};

export const WARNING = {
  en_US: 'Warning',
  es_ES: 'Advertencia',
  pt_BR: 'Aviso',
};

export const HIGHLIGHTNOTE_TRIP_CANCELLATION = {
  en_US:
    'If your <strong>trip has been rescheduled or delayed for more than 4 hours, request a Trip Delay reimbursement</strong>. The Trip Cancellation benefit should only be used if you have canceled the trip yourself.',
  es_ES:
    'Si tu <strong>viaje fue reprogramado o retrasado por más de 4 horas, solicita un reembolso por Demora de Viaje</strong>. El beneficio de Cancelación de Viaje solo debe usarse si tú mismo cancelaste el viaje.',
  pt_BR:
    'Se a sua <strong>viagem foi remarcada ou atrasada por mais de 4 horas, solicite um reembolso por Atraso de Embarque</strong>. O benefício de Cancelamento de Viagem deve ser usado apenas se você mesmo cancelou a viagem.',
};

export const HIGHLIGHTNOTE_TRIP_CANCELLATION_LINK = {
  en_US: `Request a Trip Delay reimbursement`,
  es_ES: `Solicita un reembolso por Demora de Viaje`,
  pt_BR: `Solicite um reembolso por Atraso de Embarque`,
};

export const HIGHLIGHTNOTE_BAGGAGE_LOSS = {
  en_US:
    'If your <strong>luggage was not yet officially declared lost</strong> by the airline or transport company, request a <strong>Baggage Delay</strong> reimbursement.',
  es_ES: `Si tu <strong>equipaje aún no ha sido oficialmente declarado como perdido</strong> por la aerolínea o empresa de transporte, solicita un reembolso por <strong>Retraso de Equipaje.</strong>`,
  pt_BR:
    'Se a sua <strong>bagagem ainda não foi oficialmente declarada como perdida</strong> pela companhia aérea ou empresa de transporte, solicite um reembolso por <strong> Atraso de Bagagem.</strong>',
};

export const HIGHLIGHTNOTE_BAGGAGE_LOSS_LINK = {
  en_US: `Request a Baggage Delay reimbursement`,
  es_ES: `Solicita un reembolso por Retraso de Equipaje`,
  pt_BR: `Solicite um reembolso por Atraso de Bagagem`,
};
